<template>
  <div class="login">
      <div class="header">
        <div class="header-logo">
            <img class="header-img" src="../../assets/images/login/logo1.png" alt="">
            <div class="header-right">
                <div class="header-title">榆阳产业服务平台营销商城</div>
                <div class="header-subtitle">乡村振兴丨产业兴旺丨生态宜居丨乡风文明丨治理有效丨生活富裕</div>
            </div>
        </div>
      </div>
      <div class="main">
            <div class="main-body">
                <div v-if="type != 3" class="main-box">
                    <div class="main-title">找回密码！</div>
                    <div class="title-line"></div>
                    <div class="timeline-box">
                        <div class="timeline-item">
                            <div class="item-index s-item-index">
                                <span v-if="type <= 1">1</span>
                                <i v-if="type > 1" class="el-icon-check"></i>
                            </div>
                            <span class="item-text s-item-text">验证手机号</span>
                        </div>
                        <img
                        class="main-timeline main-timeline1"
                        src="../../assets/images/login/timeline.png"
                        alt=""
                        />
                        <div class="timeline-item">
                        <div :class="['item-index', type > 1 ? 's-item-index' : '']">
                            <i v-if="type > 2" class="el-icon-check"></i>
                            <span v-if="type <= 2">2</span>
                        </div>
                            <span :class="['item-text', type > 1 ? 's-item-text' : '']">重置密码</span>
                        </div>
                    </div>
                    <div v-show="type == 1">
                        <div class="main-ipt-box">
                            <input class="main-ipt" v-model="phone" type="text" placeholder="请输入手机号">
                        </div>
                        <!-- 短信登录 -->
                        <div class="main-sms-box main-ipt-box flex-s-b">
                            <input type="text" v-model="verification" class="sms-ipt"  placeholder="请输入验证码">
                            <div class="main-line main-sms" ></div>
                            <div class="main-sms-btn">获取验证码</div>
                        </div>
                        <div class="login-btn" @click="next">下一步</div>
                    </div>
                    <div v-show="type == 2">
                        <div class="main-ipt-box">
                            <input class="main-ipt" v-model="password" type="text" placeholder="请输入新密码">
                        </div>
                       <div class="main-ipt-box">
                            <input class="main-ipt" v-model="newPassword" type="text" placeholder="请再次输入新密码">
                        </div>
                        <div class="login-btn" @click="changeOk">确定</div>
                    </div>

                    <div class="already" v-if="type == 3">
                        <!-- <img class="already-icon" src="../../assets/images/login/success.png" alt=""> -->
                        <div class="already-text">修改密码成功</div>
                        <div class="already-text">请使用新密码登录</div>
                        <el-button type="primary" @click="login" class="button-bg">返回登录 ({{second}})</el-button>
                    </div>
                   
                    <div class="main-type-box">
                        <span class="tel-type" @click="goReset">返回登录</span>
                    </div>
                </div>  
            </div>
      </div>
      <div class="footer">
            <div class="main-type-box">
                <span class="footer-type">关于我们</span>
                <div class="main-line main-line3"></div>
                <span class="footer-type">联系我们</span>
            </div>
            <div class="footer-title">
              <span>Copyright&copy;2004-2021</span>
              <span> 蜂窝FW.com 版权所有</span>  
            </div>
      </div>
      <!-- <div class="login-content">
          <div class="login-logo"><img src="../../assets/images/login/logo.png" alt=""></div>
          <el-tabs>
            <el-tab-pane label="账号登陆" name="">
                <div style="margin-top: 20px">   
                    <el-input v-model="name" prefix-icon="el-icon-s-custom" placeholder="请输入账户/手机"></el-input>
                </div>

                <div style="margin-top: 20px">
                    <el-input v-model="password" type="password" prefix-icon="el-icon-lock" placeholder="请输入登录密码"></el-input>
                </div>
                
                <el-button type="primary" @click="login" class="button-bg">登录</el-button>

                <div style="margin-top: 20px">
                    <el-checkbox style="float: left">记住登录状态</el-checkbox>
                    <a @click="goReset" style="font-size:14px; float: right; color: #F76B0E;">忘记密码？</a>
                </div>
                
            </el-tab-pane>
            <el-tab-pane label="短信登陆" name="second">短信登陆</el-tab-pane>
        </el-tabs>
      </div> -->
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'login',
  data(){
      return{
          phone: '',
          verification: '',
          password: '',
          type: 1, //判断登录状态 1账号密码登录  2 短信登录 3二维码登录
          second: 5,
          
      }
  },
  mounted(){
  },
  methods:{
      next() {
        if( this.phone == '' ) {
            this.$message.error('请填写账号');
            return
        }
        if( this.verification ==  '') {
            this.$message.error('请填写验证码');
            return
        }
        this.type = 2;
      },
      changeOk() {
         
      },
      login(){
        var data = {
            name: this.name,
            password: this.password
        }
        sessionStorage.removeItem("farmObj");
        this.qa.loginIn(data).then(res => {
            if(res.respCode == "0000"){
                // sessionStorage.removeItem("menuCode");
                sessionStorage.setItem("userData",JSON.stringify(res.data));
                sessionStorage.setItem("menuCode",JSON.stringify({name: '首页', permissionId: 20, permissionParentId: 3}))
                this.$router.push({
                    name: 'index'
                })
            } else {
                this.$message.error(res.respDesc);
            }
        })
        // post('/user/login', data).then(res => {
        //     console.log(res)
        // })
      },
      changeLogin(){
          if(this.loginType == "切换到手机号登陆"){
              this.loginType = "切换到用户名登陆";
          } else {
              this.loginType = "切换到手机号登陆";
          }
      },
      goReset(){
          this.$router.push({
              name: "login",
          })
      }
  }
}
</script>
<style scoped>
   .login{
       width: 100%;
       height: 100%;
       text-align: left;
    }
    .main {
        width: 100%;
        /* height: calc(100% - 257px); */
        /* height: 823px; */
        height: 600px;
        background: url(../../assets/images/login/bg.png);
        background-size: 100% 100%;
    }
    .main-body {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .main-title {
        font-size: 24px;
        color: #333;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .sms-ipt {
        width: 217px; 
        height: 44px;
        background: 0;
        border: 0;
        
    }
    .main-ipt-box {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        padding: 0 15px;
        border: 1px solid #D8D8D8;
        margin-bottom: 30px;
        position: relative;
    }
    .main-ipt {
        width: 100%;
        height: 50px;
        background: 0;
        border: 0;
    }
    .main-type-box {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #666666;
        margin-top: 30px;
    }
    .main-type-img {
        width: 16px;
        height: 16px;
        display: block;
        margin-right: 4px;
    }
    .main-ipt2 {
         margin-bottom: 25px;
     }
    .login-btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        background: #84C754;
        cursor: pointer;
        border-radius: 8px;
        margin-top: 25px;
    }
    .header {
        width: 1200px;
        height: 126px;
        margin: 0 auto;
        padding: 29px 0 25px;
    }
    .header-logo {
        display: flex;
        height: 72px;
        align-items: flex-end;
    }
    .header-img {
        width: 74px;
        height: 72px;
        margin-right: 21px;
    }
    
    .header-title {
        font-size: 30px;
        font-weight: 600;
        color: #333333;
    }
    .header-subtitle {
        font-size: 12px;
        color: #666666;
        margin-top: 3px;
    }
    .footer {
        width: 1200px;
        margin: 0 auto;  
        padding: 40px 0;  
    }
    .main-sms-box {
        align-items: center;
    }
    .main-box {
        width: 470px;
        /* height: 530px; */
        padding: 56px 55px 34px;
        background: url(../../assets/images/login/loginBg.png);
        background-size: 100% 100%;
        box-sizing: border-box;
    }
    .main-line {
        width: 1px;
        height: 15px;
        background: #666666;
        margin: 0 10px;
    }
    .login-type {
        cursor: pointer;
    }
    .forget-password {
        width: 100%;
        text-align: right;
        color: #666;
        margin: 5px 0 21px;
        font-size: 14px;
        cursor: pointer;
    }
    .tel-type {
        color: #366713;
        font-size: 14px;
        cursor: pointer;
    }
    .main-line2 {
        background: #366713;
        margin: 0  15px;
    }
    .main-line3 {
        margin: 0  23px;
        background: #999;
    }
    .footer-type {
        font-size: 14px;
        color: #999;
    }
    .footer-title {
      font-size: 14px;
      color: #999;
      margin: 20px;
      text-align: center;
    }
    .main-sms-btn {
        flex: 1;
        height: 48px;
        line-height: 48px;
        color: #84C754;
        font-size: 16px;
        cursor: pointer;
    }
    .main-sms {
        height: 34px;
        background: #D8D8D8;
    }
    .flex-s-c {
        align-items: center;
    }
    .title-line {
        width: 50px;
        height: 4px;
        border-radius: 50px;
        background: #84C754;
        margin-bottom: 40px;
    }
     .item-index {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50px;
        box-sizing: border-box;
        background: 0;
        border: 1px solid #999999;
        color: #999999;
        margin: 0 auto 16px;
        font-size: 18px;
        text-align: center;
    }
    .s-item-index {
        border: 0;
        /* background-image: linear-gradient(#ffc64d, #ff9354); */
        background: #84C754;
        color: #fff;
        font-size: 22px;
    }
    .item-text {
        font-size: 14px;
        color: #999999;
    }
    .s-item-text {
        color: #84C754;
    }
    .timeline-box {
        width: 240px;
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 44px auto 50px;
    }
    .main-timeline {
        width: 126px;
        height: 16px;
        position: absolute;
        top: 8px;
    }
    .main-timeline1 {
        left: 65px;
    }
</style>